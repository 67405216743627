import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col, Drawer } from "antd";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import i18n from "i18next";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  PhoneNumber,
} from "./styles";
import { LanguageSwitchContainer, LanguageSwitch } from "../Footer/styles";

const Header = ({ t }: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const toggleDrawer = () => {
    setIsVisible((prevState) => !prevState);
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
      setIsVisible(false);
    } else {
      console.warn(`No element found with ID: ${id}`);
    }
  };

  const MenuItems = () => (
    <>
      {["intro", "mission", "services"].map((item, idx) => (
        <CustomNavLinkSmall key={idx} onClick={() => scrollTo(item)}>
          <Span>{t(item.charAt(0).toUpperCase() + item.slice(1))}</Span>
        </CustomNavLinkSmall>
      ))}
      <CustomNavLinkSmall
        style={{ width: "180px" }}
        onClick={() => scrollTo("contact")}
      >
        <Span>
          <Button>{t("Contact")}</Button>
        </Span>
      </CustomNavLinkSmall>
      <CustomNavLinkSmall>
        {t("Language")}
        <LanguageSwitchContainer>
          <LanguageSwitch onClick={() => handleChange("en")}>
            <SvgIcon
              src="united-states.svg"
              aria-label="homepage"
              width="30px"
              height="30px"
            />
          </LanguageSwitch>
          <LanguageSwitch onClick={() => handleChange("es")}>
            <SvgIcon
              src="spain.svg"
              aria-label="homepage"
              width="30px"
              height="30px"
            />
          </LanguageSwitch>
        </LanguageSwitchContainer>
      </CustomNavLinkSmall>
    </>
  );

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <Col>
            <LogoContainer to="/" aria-label="homepage">
              <SvgIcon src="logo.svg" width="315px" height="64px" />
            </LogoContainer>
            {/* Make the phone number clickable */}
            <PhoneNumber>
              <a
                href="tel:1-203-283-1403"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                1-203-283-1403
              </a>
            </PhoneNumber>
          </Col>
          <NotHidden>
            <MenuItems />
          </NotHidden>
          <Burger onClick={toggleDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={isVisible} onClose={toggleDrawer}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleDrawer}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItems />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
