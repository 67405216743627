import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  FooterContainer,
  Language,
} from "./styles";
import { useState, useEffect } from "react";

interface SocialLinkProps {
  href: string;
  src: string;
}

interface LargeLinkProps {
  left?: string;
  to: string;
  children: any;
  onClick?: () => void;
}

const Footer = ({ t }: any) => {
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const LargeLink = ({ left, to, children, onClick }: LargeLinkProps) => {
    // Add onClick here
    return (
      <Large left={left} to={to} onClick={onClick}>
        {" "}
        {children}
      </Large>
    );
  };

  //TODO implement Handling the behavior of the browser's back button

  const [googleMapsUrl, setGoogleMapsUrl] = useState<string | null>("");

  const address = {
    name: "Sara Orozco Law Office LLC",
    streetAddress: "307 Bridgeport Ave",
    cityStateZip: "Milford, CT 06460",
  };

  useEffect(() => {
    const constructedUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      `${address.name}, ${address.streetAddress}, ${address.cityStateZip}`
    )}`;
    setGoogleMapsUrl(constructedUrl);
  }, [address.cityStateZip, address.name, address.streetAddress]);

  const GoogleMapsLink = ({ children }: { children: React.ReactNode }) =>
    googleMapsUrl ? (
      <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ) : null;

  const handleTermsClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            {/* Contact */}
            <Col lg={5} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <a href="mailto:info@saraorozcolaw.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            {/* Policy */}
            <Col lg={5} md={5} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
              <LargeLink
                left="true"
                to="/privacy-policy"
                onClick={handleTermsClick}
              >
                {t("Privacy Policy")}
              </LargeLink>
              <LargeLink
                left="true"
                to="/terms-and-conditions"
                onClick={handleTermsClick}
              >
                {t("Terms & Conditions")}
              </LargeLink>
            </Col>
            {/* Address */}
            <Col lg={5} md={5} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>
                <GoogleMapsLink>{address.name}</GoogleMapsLink>
                <br />
                <GoogleMapsLink>{address.streetAddress}</GoogleMapsLink>
                <br />
                <GoogleMapsLink>{address.cityStateZip}</GoogleMapsLink>
              </Para>
            </Col>
            {/* Company */}
            <Col lg={5} md={5} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <LargeLink left="true" to="/">
                {t("About")}
              </LargeLink>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="400px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://facebook.com/saraorozcolaw"
                src="facebook.svg"
              />
              <SocialLink
                href="https://g.page/r/CcN-k5-RH5l8EBM"
                src="google.svg"
              />
              <SocialLink href="https://wa.me/12038060509" src="whatsapp.svg" />
              <SocialLink
                href="https://www.youtube.com/@ABOGADASARAOROZCO"
                src="youtube.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
