// Importing the StyledContainer for styling and the ContainerProps type for prop type definitions
import { StyledContainer } from "./styles";
import { ContainerProps } from "../types";

/**
 * Container component serves as a wrapper component with optional border styling.
 *
 * @param {boolean} border - Determines if the container should have a border.
 * @param {ReactNode} children - Children components to be rendered inside the container.
 */
const Container = ({ border, children }: ContainerProps) => (
  <StyledContainer border={border}>{children}</StyledContainer>
);

export default Container;
