import React from 'react';
import Container from '../common/Container';

const Footer: React.FC = () => {
  return (
    <Container>
      <h1>Terms & Conditions</h1>

      <p><strong>Terms of Use</strong></p>
      <p>Welcome to Sara Orozco Law Office LLC, website ("Site"). By accessing or using this Site, you agree to be bound by the following terms and conditions ("Terms of Use"). Please read them carefully before proceeding.</p>

      <p><strong>No Legal Advice</strong></p>
      <p>The content provided on this Site is for informational purposes only and should not be construed as legal advice. The materials on this Site do not create an attorney-client relationship between you and Sara Orozco Law Office LLC. You should not rely on the information on this Site as a substitute for legal advice from a qualified attorney. If you require legal advice, you should consult with an attorney licensed to practice law in your jurisdiction.</p>

      <p><strong>Accuracy of Information</strong></p>
      <p>Sara Orozco Law Office LLC strives to provide accurate and up-to-date information on this Site, but we make no representations or warranties regarding the accuracy, completeness, or reliability of the content. The information provided on this Site may be subject to change without notice.</p>

      <p><strong>Use of Content</strong></p>
      <p>The materials and content on this Site, including but not limited to text, images, graphics, videos, and logos, are protected by copyright and other intellectual property laws. You may not modify, reproduce, distribute, transmit, display, perform, publish, license, create derivative works from, or sell any information or content obtained from this Site without the prior written consent of Sara Orozco Law Office LLC.</p>

      <p><strong>Links to Third-Party Websites</strong></p>
      <p>This Site may contain links to third-party websites for your convenience. Sara Orozco Law Office LLC is not responsible for the content of these third-party websites or any damages or losses that may arise from your use of them. The inclusion of links to third-party websites does not imply endorsement or affiliation with the linked sites.</p>

      <p><strong>Account Creation and Confidentiality</strong></p>
      <p>Certain features of this Site may require you to create an account. You are responsible for maintaining the confidentiality of your account information and agree to accept responsibility for all activities that occur under your account. You should notify us immediately if you suspect any unauthorized use of your account. Please be aware that the transmission of information via the internet is not completely secure, and we cannot guarantee the confidentiality of information transmitted to us through this Site.</p>

      <p><strong>Limitation of Liability</strong></p>
      <p>Sara Orozco Law Office LLC and its affiliates, partners, agents, and employees shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of this Site. Your use of this Site is at your own risk.</p>

      <p><strong>Governing Law and Jurisdiction</strong></p>
      <p>These Terms of Use shall be governed by and construed in accordance with the laws of Connecticut, without regard to its conflict of laws principles. Any legal action or proceeding arising out of or relating to these Terms of Use shall be brought exclusively in the state or federal courts located in New Haven county, Connecticut, and you consent to the personal jurisdiction of such courts.</p>

      <p><strong>Updates and Modifications</strong></p>
      <p>Sara Orozco Law Office LLC reserves the right to update or modify these Terms of Use at any time without prior notice. By continuing to access or use this Site after any such changes, you agree to be bound by the revised Terms of Use.</p>

      <p>If you have any questions or concerns about these Terms of Use, please contact us at info@saraorozcolaw.com.</p>

      <p>Thank you for using the Sara Orozco Law Office LLC website.</p>
    </Container>
  );
}

export default Footer;