import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    // Font Face declarations
    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
    }

    // Base styles
    body, html, a {
        font-family: 'Motiva Sans Light', sans-serif;
    }

    p, ul, li {
        font-family: 'Motiva Sans Light', sans-serif;
        color: #18216d;
        font-size: 21px;
    }

    body {
        margin: 0;
        padding: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a {
        text-decoration: none;
        color: #2E186A;

        &:hover {
            color: #18216d;
        }
    }

    strong, b {
        font-family: 'Motiva Sans Bold', serif;
        font-weight: bold;
    }

    // Input Styles
    input, textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;
        outline: none;
        width: 100%;
        padding: 1rem 1.25rem;

        &:focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    // Headings
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #18216d;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
            font-size: 47px;
        }

        @media only screen and (max-width: 414px) {
            font-size: 32px;
        }
    }

    h1 {
        font-weight: 600;
    }

    // Lists
    ul {
        list-style: none;
        padding-left: 0;
    }

    li {
        margin-bottom: 8px;
    }

    *:focus {
        outline: none;
    }

    // Specific Classes
    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
