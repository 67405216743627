// Importing necessary libraries and components for the main entry point of the application
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import Router from "./router";
import i18n from "./translation";

/**
 * Function to get the browser's preferred language.
 * @returns {string} - The two-letter language code (e.g., 'en', 'es').
 */
const getBrowserLanguage = () => {
  const lang = navigator.language || navigator.languages[0];
  return lang.split("-")[0]; // Extract the language code (e.g., 'en' from 'en-US')
};

/**
 * Main App component that sets up the routing and internationalization for the entire application.
 */
const App = () => {
  useEffect(() => {
    const browserLanguage = getBrowserLanguage();
    const availableLanguages = ["en", "es"];
    if (availableLanguages.includes(browserLanguage)) {
      i18n.changeLanguage(browserLanguage);
    } else {
      i18n.changeLanguage("en"); // Default to English if the detected language is not supported
    }
  }, []);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
