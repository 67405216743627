import React from "react";
import Container from "../common/Container";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <h1>Privacy Policy - Sara Orozco Law Office, LLC</h1>

      <p>
        Sara Orozco Law Office, LLC ("we", "us", or "our") values your privacy
        and is committed to protecting your personal information. This Privacy
        Policy outlines how we collect, use, share, and protect your information
        when you visit our website and use our services. By accessing our
        website, you consent to the practices described in this Privacy Policy.
      </p>

      <p>
        <strong>Personal Information We Collect</strong>
      </p>
      <p>
        The types of personal information we collect depend on the services you
        request and the nature of our representation or your case. The
        categories of personal information we may collect include, but are not
        limited to:
      </p>
      <ul>
        <li>
          Individual Identifiers and Demographic Information: Contact
          information, identifiers such as client ID and IP address, and
          demographic data.
        </li>
        <li>
          Sensitive Personal Information: Government ID numbers, financial
          information, medical records, and other sensitive data.
        </li>
        <li>
          Geolocation Data: Precise physical location if you consent to its
          collection.
        </li>
        <li>
          Sensory Data: Audio recordings, photographs, videos, or similar data
          related to your case.
        </li>
        <li>
          Biometric Information: Facial scans or fingerprint scans for account
          authentication.
        </li>
        <li>
          Commercial Information: Representation details, account information,
          and communications related to your case.
        </li>
        <li>
          Internet or Network Activity: Online activity information, device
          details, and other data collected automatically.
        </li>
        <li>
          Professional or Employment-Related Information: Job application
          details and employment history.
        </li>
        <li>Education Information: Educational records and history.</li>
        <li>
          Inferences Drawn from Personal Information: Profiles reflecting
          preferences, behavior, attitudes, or abilities.
        </li>
      </ul>

      <p>
        <strong>How We Collect Personal Information</strong>
      </p>
      <p>
        We collect personal information you provide to us, including contact
        details, during your interactions with our Platform. Additionally,
        certain information may be collected automatically using technologies
        like cookies, pixels, and analytic tools when you use our website.
      </p>

      <p>
        <strong>How We Use Your Personal Information</strong>
      </p>
      <p>
        We use your personal information to provide our services, communicate
        with you, improve our Platform, and comply with legal obligations. We
        may also use your information for research and development purposes,
        interest-based advertising, and hiring personnel.
      </p>

      <p>
        <strong>Sharing Your Personal Information</strong>
      </p>
      <p>
        We may share your personal information with third-party service
        providers, other law firms or lawyers for joint representation,
        authorities for legal compliance, business partners, professional
        advisors, affiliates, and with your consent.
      </p>

      <p>
        <strong>Your Privacy Choices</strong>
      </p>
      <p>
        You can opt-out of marketing communications and interest-based
        advertising. You can also limit the use of cookies and exercise certain
        privacy rights if you are a California resident.
      </p>

      <p>
        <strong>Information Security</strong>
      </p>
      <p>
        While we take measures to protect your personal information, no security
        system is impenetrable. We are not responsible for information
        transmitted over networks we do not control.
      </p>

      <p>
        <strong>Linked Websites</strong>
      </p>
      <p>
        This Privacy Policy does not apply to third-party websites accessed
        through links on our Platform. We encourage you to review their privacy
        policies.
      </p>

      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        For any questions or concerns about this Privacy Policy or our privacy
        practices, please contact us at:
      </p>
      <ul>
        <li>Email: info@saraorozcolaw.com</li>
        <li>Phone: 203-283-1403</li>
        <li>Address: 307 Bridgeport Ave, Milford CT 06460</li>
      </ul>

      <p>
        <strong>Changes to this Privacy Policy</strong>
      </p>
      <p>
        We may update this Privacy Policy periodically, and the changes will be
        effective upon posting on our website. Please review the "Last Updated"
        date at the bottom of the policy.
      </p>

      <p>
        <strong>Last Updated: 08/10/2023</strong>
      </p>
    </Container>
  );
};

export default PrivacyPolicy;
